<template>
  <div justify="end">
    <v-dialog
      v-model="isVisibleShow"
      max-width="1200px"
    >
      <v-card elevation="0">
        <v-card-title class="pb-4">
          <span class="text-h3">{{ $t("order") }}</span>
        </v-card-title>
        <v-card-text class="pa-0 grey lighten-4">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-card elevation="0">
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td>{{ $t("tracking_number") }}</td>
                            <td>{{ order.tracking_number }}</td>
                          </tr>
                          <tr v-if="order.delivered_code">
                            <td>{{ $t("delivered_code") }}</td>
                            <td>{{ order.delivered_code }}</td>
                          </tr>
                          <tr>
                            <td>{{ $t("client_name") }}</td>
                            <td>{{ order.client_name }}</td>
                          </tr>
                          <tr>
                            <td>{{ $t("client_phone") }}</td>
                            <td>{{ order.client_phone }}</td>
                          </tr>
                          <tr>
                            <td>{{ $t("order_origin") }}</td>
                            <td>
                              {{
                                order.order_origin
                                  ? order.order_origin.name
                                  : "_"
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>{{ $t("order_status") }}</td>
                            <td>
                              {{
                                order.order_status
                                  ? order.order_status.name
                                  : "_"
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>{{ $t("quantity_subtracted") }}</td>
                            <td>
                              {{ order.is_qte_subtracted ? "yes" : "No" }}
                            </td>
                          </tr>
                          <tr>
                            <td>{{ $t("shipping_method") }}</td>
                            <td>
                              {{
                                order.shipping_method
                                  ? order.shipping_method.name
                                  : "_"
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>{{ $t("payment_method") }}</td>
                            <td>
                              {{
                                order.payment_method
                                  ? order.payment_method.name
                                  : "_"
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>{{ $t("hub") }}</td>
                            <td>{{ order.hub ? order.hub.name : "_" }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="order.products && order.products.length > 0">
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-card elevation="0">
                  <v-card-title class="text-left">
                    <span class="text-h3">{{ $t("products.products") }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ $t("sku") }}
                            </th>
                            <th class="text-left">
                              {{ $t("name") }}
                            </th>
                            <th class="text-left">
                              {{ $t("products.marketing_designation") }}
                            </th>
                            <th class="text-left">
                              {{ $t("quantities") }}
                            </th>

                            <th class="text-left">
                              {{ $t("price_unit") }}
                            </th>
                            <th class="text-left">
                              {{ $t("total") }}
                            </th>
                            <th class="text-left">
                              {{ $t("tva") }}
                            </th>
                            <th class="text-left">
                              {{ $t("discount") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in order.products"
                            :key="item.id"
                          >
                            <td>{{ item.sku }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.name_marketing }}</td>
                            <td>{{ item.qte_in_order }}</td>
                            <td>{{ item.order_price_ht }}</td>
                            <td>
                              {{
                                Number(
                                  item.order_price_ht * item.qte_in_order
                                ).toFixed(2)
                              }}
                            </td>
                            <td>{{ item.order_tva }}</td>
                            <td>{{ item.discount }}</td>
                            <!-- <td>
                              {{ sumField("order_price_ht")?.toFixed(2) }}
                            </td> -->
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-card elevation="0">
                  <v-card-title class="text-left">
                    <span class="text-h3">{{ $t("Invoice_information") }}</span>
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td>{{ $t("price_ht") }}</td>
                            <td>
                              {{ sumField().toFixed(2) }} {{ user.currency }}
                            </td>
                          </tr>
                          <tr>
                            <td>{{ $t("tva") }}</td>
                            <td>
                              {{ calculateTva().toFixed(2) }}
                              {{ user.currency }}
                            </td>
                          </tr>
                          <tr>
                            <td>{{ $t("reduction") }} {{ user.currency }}</td>
                            <td>{{ order.reduction }} {{ user.currency }}</td>
                          </tr>
                          <tr>
                            <td>{{ $t("coupon") }}</td>
                            <td>
                              {{ order.coupon_amount }} {{ user.currency }}
                            </td>
                          </tr>
                          <tr>
                            <td>{{ $t("ttc") }}</td>
                            <td>{{ order.items_total }} {{ user.currency }}</td>
                          </tr>
                          <tr>
                            <td>{{ $t("frais_livraison") }}</td>
                            <td>
                              {{ order.shipping_cost }} {{ user.currency }}
                            </td>
                          </tr>
                          <tr>
                            <td>{{ $t("frais_service") }}</td>
                            <td>
                              {{ order.service_cost }} {{ user.currency }}
                            </td>
                          </tr>
                          <tr>
                            <td>{{ $t("timbre") }}</td>
                            <td>
                              {{ order.stamp_amount }} {{ user.currency }}
                            </td>
                          </tr>

                          <tr>
                            <td class="text-h4">{{ $t("total_amount") }}</td>
                            <td class="text-h4">
                              {{ TotalNet().toFixed(2) }} {{ user.currency }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="closeForm()"
          >
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      order: "orders/order",
      user: "users/editedUser",
    }),

    isVisibleShow: {
      get() {
        return this.$store.state.orders.isVisibleShow;
      },
      set(value) {
        this.$store.commit("orders/IS_VISIBLE_SHOW", value);
      },
    },
  },
  mounted() {
    const editedUserJSON = localStorage.getItem("user");
    if (editedUserJSON) {
      const editedUser = JSON.parse(editedUserJSON);
      this.$store.commit("users/EDITED_USER", editedUser);
    }
  },
  data() {
    return {
      tva: 0,
    };
  },
  methods: {
    closeForm() {
      console.log(this.order.stamp_amount);
      this.$store.dispatch("orders/closeForm");
    },
    sumField() {
      const Products = this.order.products;
      var total = 0;
      Products?.forEach((element) => {
        const price = parseFloat(element.order_price_ht * element.qte_in_order);
        this.tva = element.order_tva;
        total += price;
      });
      return total;
    },
    calculateTva() {
      const Products = this.order.products;
      var total_tva = 0;
      Products?.forEach((element) => {
        const tva =
          parseFloat(
            element.order_price_ht * element.qte_in_order * element.order_tva
          ) / 100;
        this.tva = element.order_tva;
        total_tva += tva;
      });
      return total_tva;
    },
    TotalNet() {
      var total = 0.0;
      total =
        parseFloat(this.order.items_total) +
        parseFloat(this.order.shipping_cost) +
        parseFloat(this.order.service_cost) +
        parseFloat(this.order.stamp_amount);

      return total;
    },
  },
};
</script>
